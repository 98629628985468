import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgServices from '../images/top_services.jpg'

const IndexPage = () => {


  return (
    <Layout pageTitle="Our Services" headerImage={imgServices}>
      <SEO title="Our Services"/>
      <div>
        <h2 className="section-title">Our Services</h2>
        <p className="general-text">We are primarily a private dental practice providing care to adults and children.
          However, we also offer care to a limited number of NHS patients.</p>
      </div>
      <div className="new-row">
        <div className="two-col-1">
          <h3 className="info-title">Appointments</h3>
          <p className="general-text">To book an appointment please telephone or email our friendly reception staff, who
            will be happy to help.</p>
          <br/>
          <h3 className="info-title">Facilities</h3>
          <p className="general-text">We have a relaxing waiting room and reception area with a variety of
            magazines.</p>
          <p className="general-text">There is also a children's play area with toys and books because we want your
            child's first experience of dental care to be a positive one.</p>
          <p className="general-text">Games and educational learning will help teach your child how to look after their
            teeth and the importance of healthy food choices. They will also learn important brushing techniques. These
            are all important preventive measures to give your child the best possible start for a healthy dental
            future.</p>
          <p className="general-text">We are happy to arrange home visits for patients who cannot travel to the
            surgery.</p>
          <br/>
          <h3 className="info-title">Nervous patients</h3>
          <p className="general-text">We understand that people of all ages and backgrounds can be affected by dental
            anxiety, and are sympathetic to those who may feel nervous about dental appointments. We aim to make you
            feel comfortable and relaxed during your visits &#151; please talk to us about any concerns you may
            have.</p>
          <br/>
          <h3 className="info-title">New patients welcome</h3>
          <p className="general-text">All new patients are very welcome to our practice to meet our friendly team and
            visit our excellent facilities.</p>
          <p className="general-text">If you are happy with the care and treatment you receive, please recommend us to
            your friends and family and we would be delighted to extend the same care to them.</p>
        </div>
        <div className="two-col-2">
          <h3 className="info-title">Out of Hours Emergency Contact</h3>
          <p className="general-text">If you require emergency treatment outside surgery hours, please telephone the
            practice on 01883-712486 as early as possible and you will hear a message detailing the specific
            arrangements for that day. (Extra charges will apply for call outs outside surgery hours.)</p>
          <p className="general-text">The NHS does provide an out of hours service and the helplines are: for Surrey
            dial 111, for Sussex 0300 100 0899 and for Kent 0300 123 4412.</p>
          <p className="general-text">We would really value your feedback about your good experiences or about areas in
            which you think we can improve our patient care. Please forward any comment to Pipers Dental Practice at
            piperdental@btconnect.com or by letter or telephone.</p>
          <br/>
          <h3 className="info-title">Complaints and GDC</h3>
          <p className="general-text">All our staff adhere to the GDC standards, please visit the GDC website for more
            information.</p>
          <p className="general-text">Our complaints policy is available on request from the practice. If you are not
            satisfied with the outcome of any complaint, below are details of two relevant bodies to contact.
            <br/><br/>
            Primary Care Support Surrey &amp; Sussex Area Team<br/>
            Cedar Court<br/>
            Guildford Road<br/>
            Leatherhead<br/>
            Surrey<br/>
            KT22 9AE<br/>
            Tel: 01372 201700<br/>
            <br/>
            Dental Ombudsman<br/>
            The Parliamentary and Healthcare Ombudsmen<br/>
            Millbank Tower<br/>
            Millbank<br/>
            London<br/>
            SW1P 4QP<br/>
            Tel: 0345 015 4033<br/>
            <a className="standard-link" href="http://www.ombudsman.org.uk">www.ombudsman.org.uk</a>
          </p>
        </div>
        <div>
          <h3 className="info-title">Data Protection</h3>
          <p className="general-text">Patients have a right to expect that we deal with their medical data in a
            confidential and sensitive way. Pipers Dental Practice maintains patient records under the General Data
            Protection Regulation. We ask patients’ consent to hold their data. We hold and transport patient data
            securely. We provide patients with a right to erasure of their data should they leave the practice. In the
            event that we change our record management systems, we will conduct a data protection impact assessment to
            ensure that we maintain our high standards of data care and confidentiality.</p>
        </div>

      </div>
    </Layout>
  )
}



export default IndexPage
